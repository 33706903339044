import React from "react";
import { Container, Box, Typography } from "@mui/material";
import Layout from "../components/base-structure/Layout";
import FractionBox from "../components/home/FractionBox";
import "../styles/Pricing.scss";
import Seo from "../components/common/SEO";

const content = [
  {
    id: 1,
    metaTitle: "Subscription Packages - Invocom",
    metaDescription:
      "Best AI chatbot for smart conversations - We have three subscription packages, from basic to premium. You can also go for a customized bundle.",
  },
];

export default function pricing() {
  return (
    <Layout>
      {content.map((contents) => (
        <div key={contents.id}>
          <Seo
            title={contents.metaTitle}
            description={contents.metaDescription}
            tag="index"
            pageUrl="pricing/"
          />
        </div>
      ))}
      <Box className="pricingPage">
        <Container>
          <Box>
            <Box className="pricingHead">
              <Typography variant="h1">Pricing</Typography>
            </Box>
          </Box>
          <FractionBox />
        </Container>
      </Box>
    </Layout>
  );
}
