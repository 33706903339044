import React from "react";
import { Container, Box } from "@mui/material";
import FreeBox from "./FreeBox";
import "../../styles/Pricing.scss";

export default function FractionBox() {
  return (
    <Container>
      <Box className="fractionBox">
        <FreeBox />
      </Box>
    </Container>
  );
}
