import React from "react";
import { Link } from "gatsby";
import { Typography, Button, Box } from "@mui/material";
import Arrow from "../../images/PriceArrow.svg";
import "../../styles/PriceBox.scss";

const boxContent = [
  {
    title: "Free",
    subTitle: "Best for professional",
    priceUnit: "$",
    price: "0",
    duration: "per / month",
    buttonText: "Get Started",
    listHead: "Includes",
    listImage: { Arrow },
    listItems: {
      itemOne: "Monthly Active Users",
      itemTwo: "Custom Bot",
      itemThree: "Robust Analytics",
      itemFour: "Ticketing system",
    },
  },
  {
    title: "Pro",
    subTitle: "Best for professional",
    priceUnit: "$",
    price: "40",
    duration: "per / month",
    buttonText: "Coming Soon",
    listHead: "Includes",
    listImage: { Arrow },
    listItems: {
      itemOne: "Coming Soon",
    },
  },
  {
    title: "Ultimate",
    subTitle: "Toolset for hard players",
    priceUnit: "$",
    price: "75",
    duration: "per / month",
    buttonText: "Coming Soon",
    listHead: "Includes",
    listImage: { Arrow },
    listItems: {
      itemOne: "Coming Soon",
    },
  },
];

export default function FreeBox() {
  return (
    <>
      {boxContent.map((contents, i) => (
        <Box className="price-Box-Main-Div" key={i}>
          <Box className="pirce-Box-Content">
            {/* Title and Subtitle */}
            <Box className="boxHead">
              <Typography variant="h2">{contents.title}</Typography>
              <Typography variantMapping="p">{contents.subTitle}</Typography>
            </Box>
            {/* Pricing per month*/}
            <Box className="pricePerMonth">
              <Typography variantMapping="p" className="priceUnit">
                {contents.priceUnit}
              </Typography>
              <Typography variantMapping="p" className="price">
                {contents.price}
              </Typography>
              <Typography variantMapping="p" className="duration">
                {contents.duration}
              </Typography>
            </Box>
            {/* Button -> Get Started */}
            <Box className="getStarted-btn">
              <Link to="https://app.invocom.io/signup/" target="_blank">
                <Button className="btn" id="pricing_btn">
                  {contents.buttonText}
                </Button>
              </Link>
            </Box>
            {/* Features List */}
            <Box className="features">
              <Typography variantMapping="p">{contents.listHead}</Typography>
              <Box className="list">
                <img loading="lazy" src={Arrow} alt="" />
                <Typography variantMapping="p">
                  {contents.listItems.itemOne}
                </Typography>
              </Box>
              {contents.listItems.itemTwo &&
              contents.listItems.itemThree &&
              contents.listItems.itemFour ? (
                <>
                  <Box className="list">
                    <img loading="lazy" src={Arrow} alt="" />
                    <Typography variantMapping="p">
                      {contents.listItems.itemTwo}
                    </Typography>
                  </Box>
                  <Box className="list">
                    <img loading="lazy" src={Arrow} alt="" />
                    <Typography variantMapping="p">
                      {contents.listItems.itemThree}
                    </Typography>
                  </Box>
                  <Box className="list">
                    <img loading="lazy" src={Arrow} alt="" />
                    <Typography variantMapping="p">
                      {contents.listItems.itemFour}
                    </Typography>
                  </Box>
                </>
              ) : (
                ""
              )}
            </Box>
          </Box>
          {/* See all features */}
          <Link to="/features/">
            <Box className="seeAll">
              <Typography variantMapping="p">See all features</Typography>
            </Box>
          </Link>
        </Box>
      ))}
    </>
  );
}
